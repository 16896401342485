import Repository from './Repository'

export default {
  get(payload) {
    return Repository.get('/categories', payload)
  },
  post(payload) {
    return Repository.post('/categories', payload)
  },
  detail(id) {
    return Repository.get('/categories/' + id)
  },
  put(payload) {
    return Repository.put('/categories/' + payload.id, payload)
  },
  delete(id) {
    return Repository.delete('/categories/' + id)
  }
}
