<template>
  <main class="content">
    <b-overlay :show="loaded" rounded="sm">
      <template #overlay>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-9 d-none d-sm-block">
            <h3>
              <b-icon icon="person-lines-fill" aria-hidden="true"></b-icon>
              Checkin List
            </h3>
          </div>
        </div>
        <hr />
        <div class="col-12 col-xl-12">
          <b-container fluid class="my-1">
            <b-row>
              <b-col class="my-1">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col class="my-1">
                <b-form-group
                  label="Per page"
                  label-for="per-page-select"
                  class="mb-0"
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Main table element -->
            <b-table
              :items="listCheckin"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              show-empty
              @filtered="onFiltered"
              :striped="true"
              :bordered="true"
              :borderless="true"
              :outlined="true"
              :hover="true"
              :fixed="true"
            >
            </b-table>
            <b-col sm="4" md="4" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                first-text="First"
                prev-text="Prev"
                next-text="Next"
                last-text="Last"
              ></b-pagination>
            </b-col>
          </b-container>
        </div>
      </div>
    </b-overlay>
  </main>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      loaded: true,
      fields: [
        {
          key: "full_name",
          label: "Full name",
          sortable: false
        },
        {
          key: "name",
          label: "Shop",
          sortable: true
        },
        {
          key: "type",
          label: "Type",
          sortable: false
        },
        {
          key: "reason",
          label: "Reason",
          sortable: false
        },
        {
          key: "checkout_at",
          label: "Date",
          sortable: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 20],
      filter: null,
      filterOn: [],
      alertMsg: null,
      submitted: false
    }
  },
  computed: {
    ...mapState({
      listCheckin: (state) => state.user.listCheckin,
    })
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("user/listCheckin")
      this.totalRows = this.listCheckin.length
      this.loaded = false
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    }
  }
}
</script>

<style lang="css">
.input-error {
  border-color: #dc3545 !important;
}
.invalid-feedback {
  display: block !important;
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}
</style>