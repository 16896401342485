import axios from 'axios'
import store from '../store'
import Routes from '../routes'

const axiosRequest = axios.create({
  baseURL: 'https://ns.cabramarket.com/api'
})

function getAccessToken () {
  let token = null
  let vuexData = window.localStorage.getItem('vuex')
  if (vuexData && Object.keys(JSON.parse(vuexData)).length) {
    token = JSON.parse(vuexData).auth.token
  }
  return token
}

axiosRequest.interceptors.request.use(
  config => {
    config.saveData = config.data
    let token = getAccessToken()
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'X-From': window.location.href,
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*'
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosRequest.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    const UNAUTHORIZED_CODE = 401
    if (
      error.response.status === UNAUTHORIZED_CODE &&
      originalRequest.url.includes("/refresh")
    ) {
      store.dispatch('auth/logout')
      Routes.push('/login')
      return Promise.reject(error)
    } else if (error.response.status === UNAUTHORIZED_CODE && !originalRequest._retry) {
      originalRequest._retry = true
      return await store.dispatch('auth/refreshToken').then(() => {
        let token = getAccessToken()
        originalRequest.headers["Authorization"] = "Bearer " + token
        originalRequest.data = originalRequest.saveData
        return axiosRequest(originalRequest)
      })
    }
    return Promise.reject(error)
  }
)

export default axiosRequest