import CategoryRepository from '../../repositories/CategoryRepository'

const state = {
  categories: [],
  category: {},
  error: ''
}

const getters = {
}

const actions = {
  async get({ commit }) {
    await CategoryRepository.get()
      .then(resp => {
        commit('setCategories', resp.data.data)
      })
  },
  async detail({ commit }, id){
    await CategoryRepository.detail(id)
      .then(resp => {
        commit('setCategory', resp.data.data)
      })
  },
  async put({ commit, dispatch }, payload){
    await CategoryRepository.put(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  async post({ commit, dispatch }, payload){
    await CategoryRepository.post(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      }).catch(error => {
        commit('setError', error.response.data.message)
      })
  },
  async delete({ commit, dispatch }, id){
    await CategoryRepository.delete(id)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  setError({ commit }){
    commit('setError', '')
  },
}

const mutations = {
  setCategories (state, data) {
    state.categories = data
  },
  setCategory (state, data) {
    state.category = data
  },
  setError(state, msg){
    state.error = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
