<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3 v-if="!$route.params.id">Add new category</h3>
          <h3 v-else>Update category</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post" v-if="!loaded">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Hello there!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Name</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.name"
            :class="[$v.form.name.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.name.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.name.required"
            >
              Name is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-if="!$route.params.id">
        <label class="col-form-label col-sm-2 text-sm-end">Parent</label>
        <div class="col-sm-10">
          <v-select 
            label="name"
            v-model="form.parent_id"
            :options="categories"
            >
          </v-select>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Avatar</label>
        <div class="col-sm-10">
          <UploadImages :max="1" @change="handleAvatar" :images="form.avatar" />
          <div v-if="submitted && $v.form.avatar.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.avatar.required"
            >
              Avatar is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import UploadImages from "../../components/UploadImages"
import { mapState } from "vuex"
import { required } from "vuelidate/lib/validators"
import vSelect from "vue-select"
import 'vue-select/dist/vue-select.css';

export default {
  data() {
    return {
      form: {
        id: 0,
        name: "",
        avatar: [],
        parent_id: ""
      },
      loaded: true,
      submitted: false
    }
  },
  components: {
    UploadImages,
    vSelect
  },
  validations: {
    form: {
      name: { required },
      avatar: { required }
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.product.error,
      category: (state) => state.category.category,
      categories: (state) => state.category.categories
    })
  },
  async mounted() {
    await this.$store.dispatch("category/get")
    if (this.$route.params.id) {
      await this.getDetail(this.$route.params.id)
    }
    this.loaded = false
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch('category/detail', id)
      this.form = {
        id: this.category.id,
        name: this.category.name,
        parent_id: this.category.parent_id,
        avatar: [this.category.avatar]
      }
    },
    handleAvatar(imgs) {
      this.form.avatar = imgs
    },
    async onSave() {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.loaded = true
      const params = {
        id: this.form.id,
        name: this.form.name,
        parent_id: this.form.parent_id ? this.form.parent_id.id : this.form.parent_id,
        avatar: this.form.avatar[0]
      }
      if (!this.$route.params.id) {
        await this.$store.dispatch("category/post", params)
      } else {
        await this.$store.dispatch("category/put", params)
      }
      if (!this.error) {
        this.loaded = false
        this.$router.push("/categories")
      } else {
        this.loaded = false
      }
    }
  }
}
</script>