import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import auth from './modules/auth'
import product from './modules/product'
import user from './modules/user'
import order from './modules/order'
import category from './modules/category'

Vue.use(Vuex)

const vuexPersistLocal = new VuexPersist({
  storage: localStorage,
  reducer: (state) => ({
    auth: {
      token: state.auth.token,
      refreshToken: state.auth.refreshToken,
      userInfo: state.auth.userInfo
    },
    user: {
      listShop: state.user.listShop
    }
  })
})

export default new Vuex.Store({
  modules: {
    auth,
    product,
    user,
    order,
    category
  },
  plugins: [vuexPersistLocal.plugin]
})
