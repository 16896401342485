import Repository from './Repository'
const loginApi = `/auth/login`
const sendOtpApi = '/send-otp-password'
const sendResetEmailApi = '/send-reset-email'
const resetPasswordApi = '/reset-password'
const refreshTokenApi = '/refresh'

export default {
  sendOtp(payload) {
    return Repository.post(sendOtpApi, payload)
  },
  login(payload) {
    return Repository.post(loginApi, payload)
  },
  sendResetEmail(payload) {
    return Repository.post(sendResetEmailApi, payload)
  },
  resetPassword(payload) {
    return Repository.put(`${resetPasswordApi}/${payload.token}`, payload)
  },
  refreshToken(payload) {
    return Repository.post(refreshTokenApi, payload)
  },
  getUserInfo(){
    return Repository.get('/auth/user')
  },
}

